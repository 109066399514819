import styles from './nav.module.css';
import myImage from './images/github.png';
import { useState } from 'react';
import { Link } from 'react-router-dom';

 const Nav = () => {
    const [ dropdown,setDropdown ] = useState(false);
  
//Create function to trigger the dropdown menu after clicking on the link
//the state should be false when clicked
     const handleLinkClick = () => {
        setDropdown(false);
      };

      //set stete to hold navbar menu
    //   const [menu ,setMenu] = useState(false)

    return(
        <>
            
         <nav className={styles.navbar}>
            <div className={styles.logo}>
            <a href='#home'>
            <img src='' alt="PORTFOLIO" />
            </a>
            </div>
                    <ul className={styles.ul}>
                    <li className={styles.links}>  <a className={styles.a} href='#home'>Home</a>  </li>
                    <li className={styles.links}> <a className={styles.a} href='#about'>About</a>  </li>
                    <li className={styles.links}> <a className={styles.a} href='#services'>Services</a>  </li>
                    <li className={styles.links}> <a className={styles.a} href='#projects'>Projects</a>  </li>
                    <li className={styles.links}> <a className={styles.a} href='#contact'>Contact</a> </li>  
                    </ul>
             
                   {

                    dropdown &&
                         //set an onClick function inside the links to handle the function
                        <ul className={styles.ulHamburger}>
                        <li className={styles.links}>  <a className={styles.a} href='#' onClick={handleLinkClick} >Home</a>  </li>
                        <li className={styles.links}> <a className={styles.a} href='#about' onClick={handleLinkClick} >About</a>  </li>
                        <li className={styles.links}>  <a className={styles.a} href='#services' onClick={handleLinkClick}>Services</a> </li>
                        <li className={styles.links}> <a className={styles.a} href='#projects' onClick={handleLinkClick}>Projects</a> </li>
                        <li className={styles.links}  > <a className={styles.a} href='#contact' onClick={handleLinkClick}  >Contact</a> </li>  
                        </ul>
                    }
            
        

                <div className={styles.connect}>
                <a className={styles.letsConnect} href='#contact'> LETS CONNECT </a>
                </div>
                <button className={styles.hamburger} onClick= { () => setDropdown(!dropdown) } >
                   <span></span>
                   <span></span>
                   <span></span>
                </button>

         </nav>

         <div className={styles.line}/>
        </>
    )
}

export default Nav;