import React from 'react';
import styles from './about.module.css'


const About = () => {
    return(
        <>
            <div id='about' className={styles.body}>
                
                <h2 className={styles.h2}>About me</h2>
                

                <div className={styles.div2}>
                <p className={styles.p}> 
                   As a full-stack web developer, i am fairly proficient in a variety of web technologies 
                   which include HTML, CSS,Javascript and tailwind for frontend development.I also utilize 
                   React to develop UI components and node.js together with expressJS for server side scripting.
                   I use Git as my preferred version control system and Github to host my remote git repositories.
                </p> 
                </div>
            </div>
        </>
    )
}

export default About;