import React from 'react'
import styles from './footer.module.css';


function Footer() {
  return (
    <>
    <div className={styles.footer}>
      <div className={styles.div1}>
        <p>Don Raburu Ochieng</p>
        <h3>© 2024</h3>
      </div>

      <div className={styles.links}>
        <h2>Quick links</h2>
          <ul className={styles.ul}>
              <li className={styles.links}>  <a className={styles.a} href='#home'>Home</a>  </li>
              <li className={styles.links}> <a className={styles.a} href='#about'>About</a>  </li>
              <li className={styles.links}> <a className={styles.a} href='#services'>Services</a>  </li>
              <li className={styles.links}> <a className={styles.a} href='#projects'>Projects</a>  </li>
              <li className={styles.links}> <a className={styles.a} href='#contact'>Contact</a> </li>  
          </ul>
     </div>

     {/* <div className={styles.socials}>
      <h2>Socials</h2>
      <a href='https://www.linkedin.com/in/don-raburu-1a1159288/'><img src='https://borisochieng.web.app/image-assets/icons/linkedin-light.svg' alt='LINKEDIN IMAGE'/></a> */}
      {/* <a href=''><img src='https://borisochieng.web.app/image-assets/icons/github-light.svg' alt='LINKEDIN IMAGE'/></a> */}
      {/* <a href=''><img src='https://borisochieng.web.app/image-assets/icons/mail-light.svg' alt='LINKEDIN IMAGE'/></a>
     </div> */}
     
    </div>
    </>
  )
}

export default Footer;