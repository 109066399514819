import {React, useState, useEffect} from 'react';
import styles from './contact.module.css';
//import both cloud firestore and firebase
//import { initializeApp } from "firebase/app";
import axios from 'axios';
import { useForm } from 'react-hook-form';
//import { DevTool } from '@hookform/devtools'


// Import the functions you need from the SDKs you need
//import { initializeApp } from "firebase/app";

const Contact = () => {

const form = useForm()
//add the necessary functions to be used in the object
const { register,handleSubmit,formState,reset,control } = form;
//form submission state object
const { isSubmitSuccessful,errors } = formState;

const [showSuccessMessage, setShowSuccessMessage] = useState(false);

//show successmessage after success
const setSuccess = () => {
    setShowSuccessMessage(true)
}
//This function submits the form when the button is clicked    
    const onSubmit = async (data) => {
        try {
            const message = await axios.post("https://us-central1-raburu-6acee.cloudfunctions.net/sendEmail", data)
            console.log("message sent succesfully",message)
            setSuccess()
        } catch (error) {
            console.log(error)
        }
    };
    useEffect ( () => {
        if(isSubmitSuccessful) {
            reset();
        }
    } , [isSubmitSuccessful, reset])
    return(
        <>
          <div id='contact' className={styles.body}>
            <div className={styles.div1}>
                <div className={styles.text}>
                <h2>Contact me</h2>
                <h4>Feel free to get in touch with me</h4>
                <h3 >Email, call , or complete the form to get in contact with me</h3>
                <div className={styles.links}>
                    <img src='https://img.icons8.com/?size=80&id=FXvGwv6Msu8q&format=png' alt='LINKEDIN IMAGE'/>
                    <p>donraburu675@gmail.com</p>
                </div>
                <div className={styles.links}>
                <img src='https://img.icons8.com/?size=30&id=78382&format=png' alt='' />
                <p>0708630519</p>
                </div>
                {/* <div className={styles.links}>
                <img src='https://img.icons8.com/?size=64&id=IuI5Yd3J3qcC&format=png' alt=''/>
                <p>Don_raburu_ochieng</p>
                </div> */}
                {/* <div className={styles.links}>
                    <img src='https://img.icons8.com/?size=64&id=4Z2nCrz5iPY2&format=png' alt=''/>
                    <p>raburu_675</p>
                </div> */}
                </div>
                
            </div>
            
                <form onSubmit={handleSubmit(onSubmit)} noValidate className={styles.form}>
                <h2>Contact Me</h2>
                <div className={styles.inputContainer}>
                    <input
                    className={styles.input}
                    name='name'
                    id='name'
                    autoComplete='off'
                    /*Register the input elements by spreading the register 
                    function and pass in name of the fields */
                    {...register('name' , {
                        required:{
                            value: true,
                            message: 'field is required'
                        }
                    })}
                    />
                    <label className={styles.label}>Name</label>
                    <p className={styles.error}>{errors.name?.message}</p>
                </div>
                
                <div className={styles.inputContainer}>
                    <input
                    className={styles.input}
                    name='email'
                    id='email'
                    type='email'
                    autoComplete='off'
                    /*Register the input elements by spreading the register 
                    function and pass in name of the fields */
                    {...register('email' , {
                        required:{
                            value: true,
                            message: 'fied is required'
                        }
                    })}
                    />
                    <label className={styles.label}>Email</label>
                    <p className={styles.error}>{errors.email?.message}</p>
                </div>
                <div className={styles.inputContainer}>
                    <input
                    className={styles.input}
                    name='number'
                    id='number'
                    autoComplete='off'
                    /*Register the input elements by spreading the register 
                    function and pass in name of the fields */
                    {...register('number' , {
                        required:{
                            value: true,
                            message: 'field is required'
                        }
                    })}
                    />
                    <label className={styles.label}>Number</label>
                    <p className={styles.error}>{errors.number?.message}</p>
                </div>
                <div className={styles.inputContainer}>
                    <input
                    className={styles.input}
                    name='subject'
                    id='subject'
                    autoComplete='off'
                    /*Register the input elements by spreading the register 
                    function and pass in name of the fields */
                    {...register('subject' , {
                        required:{
                            value: true,
                            message: 'field is required'
                        }
                    })}
                    />
                    <label className={styles.label}>Subject</label>
                    <p className={styles.error}>{errors.subject?.message}</p>
                </div>
                <div className={styles.inputContainer}>
                    <textarea
                    className={styles.textarea}
                    name='message'
                    id='message'
                    placeholder=''
                    /*Register the input elements by spreading the register 
                    function and pass in name of the fields */
                    {...register('message' , {
                        required:{
                            value: true,
                            message: 'name is required'
                        }
                    })}
                    rows={5}
                    />
                    <label className={styles.label}>Message</label>
                    {/* <p className={styles.error}>{errors.message?.message}</p> */}
                </div>
                <button className={styles.button}>SUBMIT</button>

                {showSuccessMessage && (
                // <div className={`${styles.successMessage} show`}>
                    <p className={styles.successMessage}>Your message has been sent successfully!</p>
                // </div>
                )}
                </form >
                {/* <DevTool control={control} /> */}
            </div>
        </>
    )
}

export default Contact;