import React, { useState } from 'react'
import styles from './projects.module.css'
import image1 from './images/webdevelopment.png';
import image2 from './images/webdesign.png';
import image3 from './images/uxIcon.png'
import reactImage from './images/react.png';
import PopUp from './popUp';

function Projects() {
  

  //state to hold the appUI Button and view github buttons
  const [ popUp ,setPopup ] = useState({})

  // Function to control the visibility for a specific div
const displayButtons = (divId) => {
  setPopup(prevState => ({
    ...prevState,
    [divId]: !prevState[divId]
  }));
};
  return (
    <>
    <div className={styles.body} id='projects'> 
    <h2>Projects</h2>

    
    <div  className={styles.link} onClick={()=>displayButtons('ecommerce')}>
      <h3>ecommerce-website</h3>
      <p>
      a wine shop website that lists every product offered in the store, provides details on the products, 
      such as amount, brand, price, and alcohol by volume, and has an add to Cart feature that allows you 
      to add the items you've chosen to a cart for later purchase.
      </p>
      <p >Tech stacks</p>
      <div className={styles.stack} >
                <img src='https://img.icons8.com/?size=48&id=20909&format=png' alt='HTML ICON'  className={styles.image}></img>
                <img src='https://img.icons8.com/?size=48&id=4PiNHtUJVbLs&format=png' alt="TAILWIND " className={styles.image}  />
                <img src='https://img.icons8.com/?size=48&id=108784&format=png' alt='JS ICON'  className={styles.image}></img>
                <img src='https://img.icons8.com/?size=40&id=Nlsua06Gvxel&format=png' alt='REACT ICON'className={styles.image} ></img>
                <img src='https://img.icons8.com/?size=48&id=20906&format=png' alt='GIT ICON'  className={styles.image}></img>
      </div>
      <button className={styles.button}>Click to view ...</button>
      {popUp['ecommerce'] && (
          <div className={styles.popUp}>
            {/* <a href='' className={styles.a}>VIEW APP UI</a> */}
            <a href='https://github.com/raburu675/ecommerce-website' className={styles.a}>VIEW GITHUB REPO</a>
          </div>
        )}
    </div>

    <div  className={styles.link} onClick={()=>displayButtons('authentication')}>
    <h3>authentication-app</h3>
      <p>
      An authentication app is a piece of software 
      designed to give user authentication an extra security layer in a webste or Mobile Application.
      </p>
      <p >Tech stacks</p>
      <div className={styles.stack} >
                <img src='https://img.icons8.com/?size=48&id=20909&format=png' alt='HTML ICON'  className={styles.image}></img>
                <img src='https://img.icons8.com/?size=48&id=4PiNHtUJVbLs&format=png' alt="TAILWIND " className={styles.image}  />
                <img src='https://img.icons8.com/?size=48&id=108784&format=png' alt='JS ICON'  className={styles.image}></img>
                <img src='https://img.icons8.com/?size=40&id=Nlsua06Gvxel&format=png' alt='REACT ICON'className={styles.image} ></img>
                <img src='https://img.icons8.com/?size=48&id=20906&format=png' alt='GIT ICON'  className={styles.image}></img>
      </div>
      <button className={styles.button}>Click to view ...</button>
      {popUp['authentication'] && (
          <div className={styles.popUp}>
            {/* <a href='' className={styles.a}>VIEW APP UI</a> */}
            <a href='https://github.com/raburu675/authentication-app' className={styles.a}>VIEW GITHUB REPO</a>
          </div>
        )}
    </div>
    

    <div  className={styles.link} onClick={()=>displayButtons('booking')}>
      <h3>booking-app</h3>
      <p>
      A booking app, short for a booking application, is a software
      application designed to facilitate the process of making reservations,
      appointments, or bookings for various services or resources. These apps
      are used across various industries and sectors to streamline scheduling,
      improve efficiency, and enhance the overall user experience.
      </p>
      <p >Tech stacks</p>
      <div className={styles.stack} >
                <img src='https://img.icons8.com/?size=48&id=20909&format=png' alt='HTML ICON'  className={styles.image}></img>
                <img src='https://img.icons8.com/?size=48&id=4PiNHtUJVbLs&format=png' alt="TAILWIND " className={styles.image}  />
                <img src='https://img.icons8.com/?size=48&id=108784&format=png' alt='JS ICON'  className={styles.image}></img>
                <img src='https://img.icons8.com/?size=40&id=Nlsua06Gvxel&format=png' alt='REACT ICON'className={styles.image} ></img>
                <img src='https://img.icons8.com/?size=48&id=20906&format=png' alt='GIT ICON'  className={styles.image}></img>
      </div>
      <button className={styles.button}>Click to view ...</button>
      {popUp['booking'] && (
          <div className={styles.popUp}>
            {/* <a href='' className={styles.a}>VIEW APP UI</a> */}
            <a href='https://github.com/raburu675/booking-app' className={styles.a}>VIEW GITHUB REPO</a>
          </div>
        )}
    </div>
    </div>
    </>
  )
}

export default Projects;