import React from 'react';
import styles from './home.module.css';
import myImage from './images/space.jpg';
import Typed from 'react-typed';
//import { Link } from 'react-router-dom';
import About from './about'
import Services from './services';
import Contact from './contact';
import Footer from './footer'
import Projects from './Projects';

const Home = () => {
    return(
      <>
      <div className={styles.banner} id='home'>
      <div className={styles.text}>
              <p className={styles.h3}>Welcome to my portfolio</p>
              <p className={styles.p1}>Welcome to my portfolio</p>

              <div className={styles.h1}>
                <Typed className={styles.animation}
                  strings={["Hi i'm Don", "I'm a freelancer", "I'm a Fullstack web developer", ]}
                  typeSpeed={100}
                  backSpeed={100}
                  loop
                />
              </div>

              {/*Removed animation in responsive design, make sure to change its display to none in desktop view*/}
              <h2 className={styles.anime}>
                <Typed className={styles.responsiveText}
                  strings={["Hi i'm Don" ,"I'm a web developer" ,"I'm a UI/UX designer",]}
                  typeSpeed={100}
                  backSpeed={100}
                  loop
                />
              </h2>
              <p className={styles.p}>Full Stack Web Developer</p>
              <div className={styles.buttons}>
              <a className={styles.btn} href='#projects'> Projects </a>
              <a className={styles.btn} href='#services'> Skills </a>
              </div>
              <div className={styles.links}>
              <a href='https://github.com/raburu675'><img className={styles.img1} src='https://img.icons8.com/?size=64&id=4Z2nCrz5iPY2&format=png' alt='GITHUB image'/></a>
              <a href='mailto:donraburu675@gmail.com'><img className={styles.img1} src='https://img.icons8.com/?size=50&id=Y2GfpkgYNp42&format=png' alt='GMAIL image'/></a>
              {/* <a href=''><img className={styles.img} src='https://borisochieng.web.app/image-assets/icons/mail-light.svg' alt='LINKEDIN IMAGE'/></a> */}
              </div>
      </div>
      {/* <div className={styles.imgBox}>
              <img 
              className={styles.img} 
              src='https://e0.pxfuel.com/wallpapers/139/869/desktop-wallpaper-white-and-black-dress-code-28-background-thatlazyadmin-dark-code.jpg'
              alt="bannerImage" 
              />
              
      </div> */}
      </div>

      <About/>

      <Services/>

      <Projects/>

      <Contact/>

      <Footer/>

      </>
    )
}

export default Home;
